import React from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import Modal from '../../components/common/Modal';
import Input from '../../components/input/Input';
import InputTextArea from '../../components/input/InputTextArea';
import { useAppDispatch } from '../../hooks/store';
import { successMessage } from '../../hooks/useNotifications';
import { savePackageThunk } from '../../redux/reducer/listingSlice';
import { AddVenueFormData } from '../../validation/operations/addOrEditProduct/addVenueSchema';

interface PackageModalProps {
  index: number;
  isOpen: boolean;
  resetState: () => void;
  onClose: () => void;
  listingId: string;
  venueId?: string;
  register: UseFormRegister<AddVenueFormData>;
  errors: FieldErrors<AddVenueFormData>;
  watch: UseFormWatch<AddVenueFormData>;
  trigger: UseFormTrigger<AddVenueFormData>;
  setValue: UseFormSetValue<AddVenueFormData>;
}

const PackageModal: React.FC<PackageModalProps> = ({
  index,
  isOpen,
  onClose,
  listingId,
  venueId,
  resetState,
  watch,
  register,
  setValue,
  errors,
  trigger
}) => {
  const dispatch = useAppDispatch();
  const commonProps = {
    register,
    errors,
    required: true,
    topLabel: true
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        resetState();
        onClose();
      }}
      header="Add Package"
    >
      <div className="ml-6 mt-2 space-y-3 w-3/5">
        <Input {...commonProps} hidden name={`packages.${index}.id`} type="text" />
        <Input
          label="Title"
          name={`packages.${index}.title`}
          className="bg-[#f6f6f6] font-medium pl-2 pt-1 pb-1 pr-2 w-[400px] rounded"
          type="text"
          placeholder="Enter Package Title"
          {...commonProps}
        />
        <InputTextArea
          name={`packages.${index}.description`}
          label="Description"
          placeholder="Enter Description"
          {...commonProps}
          required={false}
        />
        <Input
          label="Price"
          name={`packages.${index}.price`}
          type="number"
          placeholder="Enter price"
          {...commonProps}
        />
        <Input
          label="Discount (in %)"
          name={`packages.${index}.discount`}
          type="number"
          placeholder="Enter Discount"
          {...commonProps}
        />
        <Input
          name={`packages.${index}.totalTickets`}
          label="No. of Tickets Available"
          type="number"
          placeholder="Enter no. of ticket available"
          {...commonProps}
        />

        <div className="mt-2">
          <button
            type="button"
            onClick={async () => {
              const isValid = await trigger([`packages`]);
              if (isValid) {
                const packageDetail = watch(`packages.${index}`);
                if (venueId) {
                  const { id } = await dispatch(
                    savePackageThunk({ listingId, venueId, packageDetail })
                  ).unwrap();
                  if (id) {
                    setValue(`packages.${index}.id`, id);
                  }
                  successMessage('Successfully saved package details');
                }
                onClose();
              }
            }}
            className="font-medium bg-[#35e0a1] pl-12 pt-1 mt-2 pb-1 rounded-md pr-12"
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PackageModal;
