import * as yup from 'yup';

import { getFileSchema } from '../../common';

export const addProductSchema = yup.object({
  title: yup
    .string()
    .required('Title is required')
    .matches(
      /^[A-Za-z0-9._~ ]+$/,
      "Title should only contain AlphaNumeric characters, space and '.', '_', '~'"
    )
    .matches(/^\S.*\S$|^\S$/, 'Title sould not have traling spaces'),
  tags: yup.array(yup.string().required()).required('Category Tags is required'),
  overview: yup.string().max(1000).required('Overview is required'),
  highlight: yup.string().max(500).required('Highlight is required'),
  images: yup
    .array(getFileSchema(['image/']).required())
    .max(4)
    .required('Image is required'),
  ageGroup: yup
    .object({
      from: yup.number().required().min(0),
      to: yup.number().required().min(yup.ref('from'), 'To Age must be greater than or equal to From Age')
    })
    .required()
  // bookingType: yup.string().required('Booking Type is required')
});

export type AddProductFormData = yup.InferType<typeof addProductSchema>;
