// NotFound.tsx
import React from 'react';
import { Link } from 'react-router-dom';

import Image from '../assets/not-found-background.webp';

const NotFound: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-900 via-red-800 to-yellow-500 text-white">
      <div className="max-w-md p-8 bg-white bg-opacity-10 rounded-lg shadow-lg text-center">
        <div className="relative">
          <div className="text-9xl font-extrabold opacity-80 mb-4 text-gray-100">404</div>
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-12 animate-float">
            <img src={Image} alt="Floating planet" className="w-20 h-20 rounded-full" />
          </div>
        </div>
        <h1 className="text-3xl font-bold mt-6">Oops! Page Not Found</h1>
        <p className="text-gray-300 mt-2 mb-6">
          It looks like the page you&apos;re searching for doesn’t exist.
        </p>
        <Link
          to="/"
          className="inline-block px-6 py-2 bg-red-500 rounded-full font-bold text-white hover:bg-red-600 transition duration-300"
        >
          Take Me Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
