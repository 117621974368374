import React, { useEffect } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import Input from '../../../components/input/Input';
import { VenueCardFormData } from '../../../validation/operations/products/venueCard';

interface PackageCardProps {
  index: number;
  title: string;
  bookedTickets: number;
  useForm: {
    setValue: UseFormSetValue<VenueCardFormData>;
    watch: UseFormWatch<VenueCardFormData>;
    register: UseFormRegister<VenueCardFormData>;
    errors: FieldErrors<VenueCardFormData>;
  };
}

export const PackageCard: React.FC<PackageCardProps> = ({ title, bookedTickets, index, useForm }) => {
  const totalTickets = useForm.watch(`packages.${index}.totalTickets`);
  useEffect(() => {
    useForm.setValue(`packages.${index}.availableTickets`, totalTickets - bookedTickets);
  }, [bookedTickets, index, totalTickets, useForm]);

  return (
    <div className="bg-white p-4 rounded w-full">
      <div className="mb-2">
        <span className="font-bold text-xl">{title}</span>
      </div>
      <Input {...useForm} readonly hidden name={`packages.${index}.id`} type="text" />
      <Input
        {...useForm}
        label="Price"
        name={`packages.${index}.price`}
        type="number"
        prefix="₹"
        className="text-right"
      />
      <Input
        {...useForm}
        label="Total Tickets"
        name={`packages.${index}.totalTickets`}
        type="number"
        className="text-right"
      />
      <Input
        {...useForm}
        label="Available Tickets"
        name={`packages.${index}.availableTickets`}
        type="number"
        disabled
        className="text-right"
      />
      <Input
        {...useForm}
        label="Discount"
        name={`packages.${index}.discount`}
        type="number"
        suffix="%"
        className="text-right"
      />
    </div>
  );
};
