import type { ActionReducerMapBuilder, AsyncThunk, CaseReducer } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { RootState } from './store';
import { FileType, toError, ValueOf } from '../utils/utils';

export type AsyncThunkConfig = {
  state: RootState;
};

export const withApiErrorHandler = async <T>(
  operation: () => Promise<T>,
  propId: string | undefined
): Promise<T> => {
  try {
    if (!propId) {
      throw Error('User not signed in');
    }
    return await operation();
  } catch (error) {
    if (error instanceof AxiosError && error?.response?.data?.message) {
      throw Error(error.response.data.message, { cause: error });
    } else {
      throw toError(error);
    }
  }
};

type LoadingState = Record<string, boolean>;
type ErrorState = Record<string, string>;

export interface BaseState {
  isLoading: LoadingState;
  error: ErrorState;
}

type RecursiveChangeType<T, Original, New> = {
  [K in keyof T]: T[K] extends Original
    ? New
    : T[K] extends object
      ? RecursiveChangeType<T[K], Original, New>
      : T[K];
};

export type FileTypeToString<T> = RecursiveChangeType<T, FileType, string>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericThunksType = Record<string, AsyncThunk<any, any, any>>;
type CompleteType = keyof Pick<AsyncThunk<unknown, unknown, never>, 'fulfilled' | 'pending' | 'rejected'>;
type CompletedThunks<ThunksType extends GenericThunksType> = {
  [Key in keyof ThunksType]: {
    [ActionType in CompleteType]: ThunksType[Key][ActionType];
  }[CompleteType];
}[keyof ThunksType];

export class ThunkReducerMap<State extends ValueOf<RootState>, ThunksType extends GenericThunksType> {
  private readonly map: Map<
    CompletedThunks<ThunksType>,
    CaseReducer<State, ReturnType<CompletedThunks<ThunksType>>>
  > = new Map();

  public addCase<CompletedThunk extends CompletedThunks<ThunksType>>(
    thunk: CompletedThunk,
    reducer: CaseReducer<State, ReturnType<CompletedThunk>>
  ): this {
    this.map.set(thunk, reducer);
    return this;
  }

  public getCase<CompletedThunk extends CompletedThunks<ThunksType>>(
    thunk: CompletedThunk
  ): CaseReducer<State, ReturnType<CompletedThunk>> | undefined {
    return this.map.get(thunk);
  }
}

export const addLoadingReducers = <State extends ValueOf<RootState>, ThunksType extends GenericThunksType>(
  builder: ActionReducerMapBuilder<State>,
  thunks: ThunksType,
  customCases: ThunkReducerMap<State, ThunksType>
) => {
  Object.values(thunks).forEach(thunk => {
    const thunkIsLoadingKey = thunk.pending.toString();
    const thunkErrorKey = thunk.rejected.toString();
    builder
      .addCase(thunk.pending, state => {
        state.isLoading[thunkIsLoadingKey] = true;
        delete state.error[thunkErrorKey];
      })
      .addCase(thunk.fulfilled, (state, action) => {
        customCases.getCase(thunk.fulfilled)?.(state, action);
        state.isLoading[thunkIsLoadingKey] = false;
      })
      .addCase(thunk.rejected, (state, action) => {
        state.isLoading[thunkIsLoadingKey] = false;
        const error = toError(action.error);
        // errorMessage(error.message);
        state.error[thunkErrorKey] = error.message;
        console.error(error);
      });
  });
};
