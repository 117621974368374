import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProductCard } from './sub-component/ProductCard';
import Button from '../../components/button/Button';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getProductsThunk } from '../../redux/reducer/listingSlice';

const ProductsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const products = useAppSelector(state => state.listing.products);

  useEffect(() => {
    dispatch(getProductsThunk());
  }, []);

  return (
    <div className=" bg-white pl-4 pt-2 font-medium pb-6 w-10/12">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Products</h1>
        <Button
          label="Add Product"
          className="bg-[#35E0A1] text-black px-4 py-2 rounded"
          onClick={() => navigate('/product')}
        />
      </div>
      <div>
        {products?.length > 0
          ? products?.map(product => <ProductCard key={product.id} product={product} />)
          : 'No Products added'}
      </div>
    </div>
  );
};

export default ProductsPage;
