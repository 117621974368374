import React from 'react';
import { Link } from 'react-router-dom';

import { VenueCard } from './VenueCard';
import { Product } from '../../../utils/models/listing';

interface ProductCardProps {
  product: Product;
}

export const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  return (
    <div className="bg-zinc-100 p-6 rounded shadow-md mt-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">{product.name}</h2>
        <Link to={`/product/${product.id}`} className="bg-[#35E0A1] text-black px-2 py-2 rounded">
          View / Edit Product Details
        </Link>
      </div>
      <div>
        {product.venues.map(venue => (
          <VenueCard key={venue.id} venue={venue} productId={product.id} />
        ))}
      </div>
    </div>
  );
};
