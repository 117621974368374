import * as yup from 'yup';

import { FileType } from '../utils/utils';

export const OBJECT_ID_PATTERN = /^[0-9a-fA-F]{24}$/;

export const getFileSchema = (SUPPORTED_FORMATS: Array<string>, MAX_FILE_SIZE: number = 1024 * 1024) =>
  yup
    .mixed<FileType>()
    .test('objectType', 'Type of object should be file and loc should be string', file => {
      const isFileObject = file?.obj ? file.obj instanceof File : true;
      const isFileLocString = file?.loc ? typeof file.loc === 'string' : true;
      return isFileObject && isFileLocString;
    })
    .test('fileSize', 'File size is too large', file => {
      return file?.obj ? file.obj.size <= MAX_FILE_SIZE : true;
    })
    .test('fileType', 'Unsupported file format', file => {
      return file?.obj ? SUPPORTED_FORMATS.some(format => file.obj?.type.startsWith(format)) : true;
    });
