/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Bookings = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    product: '',
    source: '',
    venue: '',
    status: '',
    bookingDateRange: {
      bkngStartDate: new Date(),
      bkngEndDate: new Date(),
      key: 'selection'
    },
    transactionDateRange: {
      txnStartDate: new Date(),
      txnEndDate: new Date(),
      key: 'selection'
    }
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const totalItems = 68;

  const [searchLabel, setSearchLabel] = useState('Enter Customer Phone Number');
  const [searchValue, setSearchValue] = useState('');

  const handleFilterChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value
    });
  };

  const handlePageChange = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    if (value === 'Booking Id') {
      setSearchLabel('Please Enter Booking Id');
    } else {
      setSearchLabel('Enter Customer Phone Number');
    }
  };

  const handleSearchInputChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) {
      return;
    }
    setSearchValue(value);
  };

  const handleAddVenue = () => {
    // alert("Add Venue clicked");
    navigate(`/bookings/booking-details`);
  };

  const renderTableRows = () => {
    const rows = [];
    for (let i = 0; i < itemsPerPage; i++) {
      rows.push(
        <tr key={i} className="border-[1px] border-[#d9d9d9] font-medium">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <td
            className="p-2 text-black cursor-pointer w-[100px] underline text-center"
            onClick={handleAddVenue}
          >
            venkata Srivatsav konda
          </td>
          <td className="p-2 text-center">13081998</td>
          <td className="p-2 w-[50px] text-center">Sid Sri Ram Live Concert Hyderabad</td>
          <td className="p-2 text-center">
            <select className="border p-1 border-none">
              <option>Type 1</option>
            </select>
          </td>
          <td className="p-2  text-center w-[150px]">13th Oct 2024</td>
          <td className="p-2  text-center w-[150px]">Type 1</td>
        </tr>
      );
    }
    return rows;
  };

  const handleBookingDateRangeChange = ({
    startDate,
    endDate
  }: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      bookingDateRange: {
        bkngStartDate: startDate ?? new Date(), // Default to current date if null
        bkngEndDate: endDate ?? new Date(),
        key: 'selection'
      }
    }));
  };

  const handleTransactionDateRangeChange = ({
    startDate,
    endDate
  }: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      transactionDateRange: {
        txnStartDate: startDate ?? new Date(), // Default to current date if null
        txnEndDate: endDate ?? new Date(),
        key: 'selection'
      }
    }));
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          className={`p-2 ${currentPage === i ? 'bg-[#1e1e1e] text-white font-medium' : 'font-medium'}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pages;
  };
  // return <h1 className="text-2xl font-bold">Bookings</h1>;
  return (
    <div className="flex ">
      {/* <button className="bg-green-500 text-white px-4 py-2 rounded">Add Product</button> */}
      <div className=" w-4/5 flex flex-col">
        <h1 className="text-2xl font-bold mt-2">Bookings</h1>
        <main className="">
          <div className="flex mb-8 mt-4 font-medium">
            <input
              type="text"
              placeholder={searchLabel}
              className=" p-2 rounded-l w-1/3 bg-[#f6f6f6]"
              value={searchValue}
              onChange={handleSearchInputChange}
            />
            <select className="border p-2 rounded-r pl-6 bg-[#d9d9d9] pr-6" onChange={handleDropdownChange}>
              <option>Mobile No.</option>
              <option>Booking Id</option>
            </select>
            <button className="bg-[#35E0A1] text-black p-2 rounded  ml-4 pl-8 pr-8">Search</button>
          </div>
          <div className="flex justify-center mb-8">
            <div className="bg-[#f9f9f9] p-4 pt-6 rounded shadow w-1/5 h-[120px] text-center mx-2">
              <p className="text-2xl font-bold">₹ 499</p>
              <p className="font-medium text-lg mt-4">Total Revenue</p>
            </div>
            <div className="bg-[#f9f9f9] p-4 pt-6 rounded shadow w-1/5 text-center mx-2">
              <p className="text-2xl font-bold">145</p>
              <p className="font-medium text-lg mt-4">No. of Bookings</p>
            </div>
          </div>
          <div className="flex justify-end mb-4">
            <span className="font-medium underline">Download</span>
          </div>
          <table className="w-full rounded border-[1px] border-[#d9d9d9] shadow">
            <thead className="bg-[#d9d9d9] h-[60px] rounded-lg">
              <tr className="font-medium">
                <th className="p-2 text-center font-medium">Customer</th>
                <th className="p-2 text-center font-medium">Booking ID</th>
                <th className="p-2 text-center font-medium">Product</th>
                <th className="p-2 text-center font-medium">Status</th>
                <th className="p-2 text-center font-medium">Booking Date</th>
                <th className="p-2 text-center font-medium">Payment Mode</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
          <div className="flex justify-between items-center mt-4">
            <button
              className="text-[#1e1e1e] font-medium"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              ← Previous
            </button>
            <div className="flex items-center">{renderPagination()}</div>
            <button
              className="text-[#1e1e1e] font-medium"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
            >
              Next →
            </button>
          </div>
        </main>
      </div>
      <div className="w-1/5">
        <aside className=" p-8" style={{ textAlign: 'left' }}>
          <div className="mb-4">
            <h2 className="font-medium mb-2">Filter</h2>
            <div className="mb-4">
              <label className="block mb-1 font-medium">By product</label>
              <select
                name="product"
                className="p-2 w-full bg-[#f6f6f6] text-[#9b9b9b]"
                onChange={handleFilterChange}
              >
                <option>Select Product</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-medium">By Source</label>
              <select
                name="source"
                className="p-2 w-full bg-[#f6f6f6] text-[#9b9b9b]"
                onChange={handleFilterChange}
              >
                <option>Select Source</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-medium">By Venue</label>
              <select
                name="venue"
                className="p-2 w-full bg-[#f6f6f6] text-[#9b9b9b]"
                onChange={handleFilterChange}
              >
                <option>Select Source</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-medium">By Status</label>
              <select
                name="status"
                className="p-2 w-full bg-[#f6f6f6] text-[#9b9b9b]"
                onChange={handleFilterChange}
              >
                <option>Select Status</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-medium">By Booking Date</label>
              <div className="flex gap-2 flex-col">
                <input
                  type="date"
                  value={filter.bookingDateRange.bkngStartDate.toISOString().split('T')[0]} // Convert Date to YYYY-MM-DD
                  onChange={e =>
                    handleBookingDateRangeChange({
                      startDate: filter.bookingDateRange.bkngStartDate,
                      endDate: e.target.value ? new Date(e.target.value) : null
                    })
                  }
                  className="bg-[#f6f6f6] p-2"
                />
                <input
                  type="date"
                  value={filter.bookingDateRange.bkngEndDate.toISOString().split('T')[0]} // Convert Date to YYYY-MM-DD
                  onChange={e =>
                    handleBookingDateRangeChange({
                      startDate: filter.bookingDateRange.bkngStartDate,
                      endDate: e.target.value ? new Date(e.target.value) : null
                    })
                  }
                  className="bg-[#f6f6f6] p-2"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-medium">By Transaction Date</label>
              <div className="flex gap-2 flex-col">
                <input
                  type="date"
                  value={filter.transactionDateRange.txnStartDate.toISOString().split('T')[0]} // Convert Date to YYYY-MM-DD
                  onChange={e =>
                    handleTransactionDateRangeChange({
                      startDate: filter.bookingDateRange.bkngStartDate,
                      endDate: e.target.value ? new Date(e.target.value) : null
                    })
                  }
                  className="bg-[#f6f6f6] p-2"
                />
                <input
                  type="date"
                  value={filter.transactionDateRange.txnEndDate.toISOString().split('T')[0]} // Convert Date to YYYY-MM-DD
                  onChange={e =>
                    handleBookingDateRangeChange({
                      startDate: filter.bookingDateRange.bkngStartDate,
                      endDate: e.target.value ? new Date(e.target.value) : null
                    })
                  }
                  className="bg-[#f6f6f6] p-2"
                />
              </div>
            </div>

            <button className="bg-[#35E0A1] text-black font-medium p-2 w-full rounded">Filter</button>
            <p className="text-red-500 mt-2">No filters selected</p>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Bookings;
