import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FieldErrors,
  FieldPathByValue,
  FieldValues,
  PathValue,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form';
interface CommonInputDateProps<TFieldValues extends FieldValues> {
  name: FieldPathByValue<TFieldValues, Date | null>;
  label: string;
  setValue: UseFormSetValue<TFieldValues>;
  watch: UseFormWatch<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  required?: boolean;
  placeholder?: string;
  timeIntervals?: number;
  dateFormat?: string;
  className?: string;
}

interface IsTimeSelectProps<TFieldValues extends FieldValues> extends CommonInputDateProps<TFieldValues> {
  isTimeSelect: true;
  defaultDate: Date | undefined;
}

interface IsNotTimeSelectProps<TFieldValues extends FieldValues> extends CommonInputDateProps<TFieldValues> {
  isTimeSelect?: false;
  defaultDate?: never;
}

type InputDateProps<TFieldValues extends FieldValues> =
  | IsTimeSelectProps<TFieldValues>
  | IsNotTimeSelectProps<TFieldValues>;

const InputDate = <TFieldValues extends FieldValues>({
  name,
  label,
  setValue,
  watch,
  errors,
  required = false,
  placeholder,
  isTimeSelect = false,
  defaultDate,
  timeIntervals = 15,
  dateFormat = isTimeSelect ? 'h:mm aa' : 'MM/dd/yyyy',
  className
}: InputDateProps<TFieldValues>): JSX.Element => {
  const value = watch(name);
  const error = _.get(errors, name)?.message as string;

  useEffect(() => {
    if (value && !((value as Date) instanceof Date)) {
      setValue(name, dayjs(value).toDate() as PathValue<TFieldValues, typeof name>);
    }
  }, [value]);

  useEffect(() => {
    if (isTimeSelect && value) {
      const currDate = dayjs(value).toDate();
      const date = dayjs(defaultDate)
        .set('hours', currDate.getHours())
        .set('minutes', currDate.getMinutes())
        .set('seconds', currDate.getSeconds())
        .toDate();
      setValue(name, date as PathValue<TFieldValues, typeof name>, { shouldValidate: true });
    }
  }, [defaultDate]);

  const handleChange = (selectedDateOrTime: Date | null) => {
    const date = selectedDateOrTime
      ? isTimeSelect
        ? dayjs(defaultDate)
            .set('hours', selectedDateOrTime.getHours())
            .set('minutes', selectedDateOrTime.getMinutes())
            .set('seconds', selectedDateOrTime.getSeconds())
            .toDate()
        : selectedDateOrTime
      : null;
    setValue(name, date as PathValue<TFieldValues, typeof name>, { shouldValidate: true });
  };
  // console.log(value);

  // const yesterday = new Date();
  // yesterday.setDate(yesterday.getDate() - 1);
  return (
    <div className="w-full mb-2">
      <label htmlFor={name} className="block mb-1 font-medium">
        {label} {required && <span className="text-red-600">*</span>}
      </label>
      <DatePicker
        selected={value}
        onChange={handleChange}
        showTimeSelect={isTimeSelect}
        showTimeSelectOnly={isTimeSelect}
        timeIntervals={timeIntervals}
        timeCaption="Time"
        dateFormat={dateFormat}
        placeholderText={placeholder ?? label}
        className={`w-full p-2 rounded bg-[#f6f6f6] ${className}`}
        id={name}
      />
      {error && <p className="text-red-600 text-sm font-semibold mt-1">{error}</p>}
    </div>
  );
};

export default InputDate;
