import React, { useState } from 'react';

interface FAQItem {
  question: string;
  answer: string;
}

const FAQSection: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const faqItems: FAQItem[] = [
    {
      question: 'How long does it take to set up my event website?',
      answer:
        'With Zittle, you can set up your event website in under 5 minutes. Simply enter your details, and your branded site is ready to go!'
    },
    {
      question: 'Do you take a commission on ticket sales?',
      answer: 'No, we do not take a commission on ticket sales.'
    },
    {
      question: 'Can I use my own custom domain for my event site?',
      answer: 'Yes, you can use your own custom domain to brand your site.'
    },
    {
      question: 'Will ticket sales go directly to my bank account?',
      answer: 'Absolutely, ticket sales are transferred directly to your bank account.'
    },
    {
      question: 'Do I get access to analytics for my events?',
      answer: 'Yes, detailed analytics are available for all your events.'
    },
    {
      question: 'How can I keep attendees informed about event updates?',
      answer: 'You can send updates to attendees via email, SMS, or in-app notifications.'
    }
  ];

  const toggleFAQ = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section id="faqs" className="bg-gray-50 pt-20 px-6">
      <div className="max-w-4xl mx-auto">
        {/* Heading */}
        <div className="text-center mb-12">
          <h3 className="uppercase tracking-wide text-lg font-semibold mb-2 text-[#FF543E]">FAQ</h3>
          <h2 className="text-3xl md:text-4xl font-bold">Frequently Asked Questions</h2>
        </div>

        {/* FAQ List */}
        <div className="space-y-6">
          {faqItems.map((item, index) => (
            <div
              key={index}
              className={`border rounded-lg ${
                activeIndex === index ? 'bg-white shadow-lg border-gray-300' : 'bg-gray-100'
              }`}
            >
              <button
                onClick={() => toggleFAQ(index)}
                className="w-full flex items-center justify-between px-6 py-4 text-left"
              >
                <span className="text-gray-800 text-lg font-medium">{item.question}</span>
                <span className={`text-xl transition-transform ${activeIndex === index ? 'rotate-180' : ''}`}>
                  {activeIndex === index ? '−' : '+'}
                </span>
              </button>
              {activeIndex === index && <div className="px-6 pb-4 text-gray-600 text-sm">{item.answer}</div>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
