import React from 'react';

const Social: React.FC = () => {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Social</h1>
      </div>
    </div>
  );
};

export default Social;
