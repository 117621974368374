import { AxiosError } from 'axios';

import { errorMessage, successMessage } from '../hooks/useNotifications';
import { Range } from './entity/common';

export type ValueOf<T> = T[keyof T];

export type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Pick<T, K> & Partial<Omit<T, K>>;
}[keyof T];

export type FileType = RequireAtLeastOne<{
  obj: File;
  loc: string;
}>;
const getCloudfrontUrl = (loc: string) =>
  loc && loc.startsWith('prop') ? `http://localhost:4566/test-bucket/${loc}` : loc;
export const getPreviewUrl = (file?: FileType) =>
  file ? (file.obj ? URL.createObjectURL(file.obj) : getCloudfrontUrl(file.loc!)) : 'UndefinedObject';

export const fileTypeFromFile = (file: File): FileType => ({ obj: file });
export const fileTypeFromLoc = (loc: string): FileType => ({ loc });

export const cityOptions = [{ id: 'Hyderabad', value: 'Hyderabad' }];

export const toError = (expectedError: unknown, unexpectedErrorMessage?: string): Error => {
  if (expectedError instanceof AxiosError && expectedError?.response?.data?.message) {
    return new Error(expectedError.response.data.message, { cause: expectedError });
  } else if (expectedError instanceof Error) {
    return expectedError;
  } else if (
    typeof expectedError === 'object' &&
    expectedError !== null &&
    'message' in expectedError &&
    typeof (expectedError as { message: unknown }).message === 'string'
  ) {
    return new Error((expectedError as { message: string }).message, { cause: expectedError });
  }

  // Fallback for other cases (e.g., primitive values)
  return new Error(unexpectedErrorMessage ?? 'Unexpected Error', { cause: expectedError });
};

export const withErrorHandler = async <T>(
  operation: () => Promise<T>,
  completeSuccessMessage?: string
): Promise<T> => {
  try {
    const res = await operation();
    if (completeSuccessMessage) {
      successMessage(completeSuccessMessage);
    }
    return res;
  } catch (err) {
    errorMessage(toError(err).message);
  }
  return null as T;
};

export const getPrettyDateString = (batch?: Range<Date>) => {
  if (!batch) {
    return '';
  }
  const from = new Date(batch.from);
  const to = new Date(batch.to);
  return `${from.toLocaleDateString()}, ${from.toLocaleTimeString('en-IN', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  })} - ${to.toLocaleTimeString('en-IN', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  })}`;
};
