import _ from 'lodash';
import { FieldValues } from 'react-hook-form';

import { InputFormFieldProps } from './Input';
interface InputTextAreaProps<TFieldValues extends FieldValues> extends InputFormFieldProps<TFieldValues> {
  label: string;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
}

const InputTextArea = <TFieldValues extends FieldValues>({
  name,
  label,
  placeholder,
  required,
  register,
  disabled,
  errors,
  maxLength
}: InputTextAreaProps<TFieldValues>): JSX.Element => {
  const error = _.get(errors, name)?.message as string;
  return (
    <div className="w-full mb-2">
      <label htmlFor={name} className="flex mb-1 font-medium items-end justify-between">
        <p>
          {label} {required && <span className="text-red-600">*</span>}
        </p>
        {maxLength && <p className="font-normal text-[14px]"> (Maximum {maxLength} characters)</p>}
      </label>
      <textarea
        id={name}
        className="p-2 block w-full border min-h-40 border-[#f6f6f6] bg-[#f6f6f6] rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500"
        rows={3}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        {...register?.(name, { required, disabled })}
      />
      {error && <p className="text-red-600 text-sm font-semibold mt-1">{error}</p>}
    </div>
  );
};

export default InputTextArea;
