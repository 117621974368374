import { Package, Slot } from '../entity/venue';

export interface GetProductsResponse {
  products: Array<{
    id: string;
    name: string;
    venues: Array<{
      id: string;
      address: string;
      city: string;
      slot: Slot;
      packages: Array<Package & { bookedTickets: number }>;
      pauseBooking: boolean;
    }>;
  }>;
}

export type Product = GetProductsResponse['products'][number];
export type ProductVenue = Product['venues'][number];
export type VenuePackage = ProductVenue['packages'][number];

export enum PresignedPostUploadType {
  PROP_DOCUMENT_GST = 'PROP_DOCUMENTS_GST',
  PROP_LOGO = 'PROP_LOGO',
  PROP_LANDING_IMAGES = 'PROP_LANDING_IMAGES',
  PROP_CATEGORY_IMAGES = 'PROP_CATEGORY_IMAGES',
  PROP_LISTING_IMAGES = 'PROP_LISTING_IMAGES'
}
