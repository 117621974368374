import * as yup from 'yup';

import { getFileSchema } from '../common';

export const businessDetailSchemma = yup.object({
  businessName: yup.string().required('Business Name is required'),
  email: yup.string().email().required('Business Email is required'),
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .matches(/^[0-9]{10}$/, 'Phone number must be a 10-digit number without any special characters'),
  alternatePhoneNumber: yup
    .string()
    .required('Alternate Phone number is required')
    .matches(/^[0-9]{10}$/, 'Phone number must be a 10-digit number without any special characters'),
  city: yup.string().required('City is required'),
  address: yup.string().required('Business address is required'),
  gst: getFileSchema(['application/pdf'], 2 * 1024 * 1024).required('GST File is required')
});

export type BusinessDetailFormData = yup.InferType<typeof businessDetailSchemma>;
