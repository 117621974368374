import _ from 'lodash';
import { useRef } from 'react';
import {
  FieldErrors,
  FieldPathByValue,
  FieldValues,
  PathValue,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form';

import { errorMessage } from '../../hooks/useNotifications';
import { getPresignedPostThunk } from '../../redux/reducer/propSlice';
import { dispatch } from '../../redux/store';
import { uploadFile } from '../../redux/thunk/propThunk';
import { PresignedPostUploadType } from '../../utils/models/listing';
import { FileType, getPreviewUrl } from '../../utils/utils';

interface CommonInputFileProps<TFieldValues extends FieldValues> {
  setValue: UseFormSetValue<TFieldValues>;
  watch: UseFormWatch<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  label: string;
  required?: boolean;
  accept: string;
  addtionalText?: JSX.Element;
}
interface InputFilePropsMultiple<TFieldValues extends FieldValues>
  extends CommonInputFileProps<TFieldValues> {
  multiple: true;
  maxFiles: number;
  name: FieldPathByValue<TFieldValues, Array<FileType>>;
}

interface InputFilePropsSingle<TFieldValues extends FieldValues> extends CommonInputFileProps<TFieldValues> {
  multiple?: false;
  maxFiles?: 1;
  name: FieldPathByValue<TFieldValues, FileType>;
}

type InputFileProps<TFieldValues extends FieldValues> =
  | InputFilePropsMultiple<TFieldValues>
  | InputFilePropsSingle<TFieldValues>;

export const uploadLocalFiles = async (
  uploadType: PresignedPostUploadType,
  ...files: Array<FileType>
): Promise<Array<string>> => {
  const localFiles: Array<File> = [];
  const s3Files: Array<string> = [];
  files.forEach(file => {
    if (file.loc) {
      s3Files.push(file.loc);
    } else if (file.obj) {
      localFiles.push(file.obj);
    }
  });
  if (localFiles.length > 0) {
    const { presignedPosts } = await dispatch(
      getPresignedPostThunk({ uploadType, noOfFiles: localFiles.length })
    ).unwrap();

    const uploadLocations = await Promise.all(
      localFiles.map(async (file, index) => (await uploadFile(presignedPosts[index], file)).location)
    );
    return s3Files.concat(uploadLocations);
  }
  return s3Files;
};

const InputFile = <TFieldValues extends FieldValues>({
  name,
  label,
  multiple = false,
  maxFiles = 1,
  required,
  addtionalText,
  accept,
  setValue,
  watch,
  errors
}: InputFileProps<TFieldValues>): JSX.Element => {
  const state = watch(name);
  const files: Array<FileType> = state ? (multiple ? state : [state]) : [];

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (selectedFiles: Array<FileType>) => {
    if (multiple) {
      const updatedFiles = [...files, ...selectedFiles];
      if (updatedFiles.length > maxFiles) {
        return errorMessage(`Only ${maxFiles} could be selected`);
      }
      setValue(name, updatedFiles as PathValue<TFieldValues, typeof name>);
    } else {
      const singleFile = selectedFiles?.length > 0 ? selectedFiles.at(0) : null;
      setValue(name, singleFile as PathValue<TFieldValues, typeof name>);
    }
    // Reset the file input to allow the same file to be selected again
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleRemoveFile = (index: number) => {
    let updatedFiles = null as PathValue<TFieldValues, typeof name>;
    if (multiple) {
      updatedFiles = files.filter((_, i) => i !== index) as PathValue<TFieldValues, typeof name>;
    }
    const file = files[index];
    if (typeof file === 'string') {
      // TODO: Add Logic to delete file in s3
      console.log('s3 path', file);
    }
    setValue(name, updatedFiles);
  };

  const error = _.get(errors, name)?.message as string;
  return (
    <div className="w-full mb-2">
      <label htmlFor={name} className="flex mb-1 font-medium items-end justify-between">
        <p>
          {label} {required && <span className="text-red-600">*</span>}
        </p>
        {maxFiles > 1 && <p className="font-normal text-[14px]"> (Maximum {maxFiles} files)</p>}
      </label>
      <button
        type="button"
        className="bg-[#f6f6f6] w-full flex justify-center items-center text-center cursor-pointer"
        onClick={() => fileInputRef.current?.click()}
      >
        <input
          type="file"
          id={name}
          ref={fileInputRef}
          accept={accept}
          multiple={multiple}
          hidden
          onChange={({ target: { files } }) =>
            handleFileChange(Array.from(files ?? []).map(obj => ({ obj })))
          }
        />
        <div className="py-12">
          <i className="fas fa-plus text-gray-400"></i>
          {addtionalText}
        </div>
      </button>

      {/* Display selected files with an option to remove */}
      {files?.length > 0 && (
        <div className="flex mt-2 flex-wrap">
          {files.map((file: FileType, index: number) => {
            const imageRegex = /\.(jpg|jpeg|png|gif|bmp|tiff|webp)$/i;
            const isImage = file.obj?.type.startsWith('image/') ?? imageRegex.test(file.loc!);
            const previewUrl = getPreviewUrl(file);
            const fileName = file.obj
              ? file.obj?.name
              : file.loc?.substring(file.loc?.indexOf('_', file.loc?.lastIndexOf('/')) + 1);

            return (
              <div
                key={index}
                className="relative flex items-center m-0.5 w-[49%] group hover:bg-gray-100 rounded-md"
              >
                {isImage ? (
                  <img
                    src={previewUrl}
                    alt={`Preview ${index + 1}`}
                    className="w-full h-auto object-cover rounded-md"
                    onLoad={() => previewUrl && URL.revokeObjectURL(previewUrl)}
                  />
                ) : (
                  <a
                    href={previewUrl}
                    className="text-sm text-gray-600 truncate w-full"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {fileName}
                  </a>
                )}
                <button
                  type="button"
                  className="absolute top-1 right-1 bg-red-500 text-white text-sm px-2 py-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                  onClick={() => handleRemoveFile(index)}
                >
                  X
                </button>
              </div>
            );
          })}
        </div>
      )}
      {error && <p className="text-red-600 text-sm font-semibold mt-1">{error}</p>}
    </div>
  );
};

export default InputFile;
