import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const activeScreen = '/' + location.pathname.split('/')[1];

  const handleNavigation = (path: string) => navigate(path);

  const SidebarButton: React.FC<{ path: string; label: string; iconClass: string }> = ({
    path,
    label,
    iconClass
  }) => (
    <button
      onClick={() => handleNavigation(path)}
      className={`flex items-center p-4 cursor-pointer w-full mt-2 ${
        activeScreen === path ? 'bg-black rounded text-white' : ''
      }`}
    >
      <div className="flex items-center justify-center gap-2">
        <div className="flex justify-center items-center bg-[#35E0A1] h-[35px] rounded-full w-[35px]">
          <i className={`fas ${iconClass} text-black`} />
        </div>
        <span>{label}</span>
      </div>
    </button>
  );
  return (
    <aside className="w-1/5 sticky top-0 bg-zinc-100 overflow-y-auto font-medium  max-h-screen">
      <nav className="pt-6 px-2 flex flex-col gap-4">
        <div className="mb-4">
          <h3 className="text-gray-500 text-left px-4">Operations</h3>
          <div>
            <SidebarButton path="/products" label="Products" iconClass="fa-box" />
            <SidebarButton path="/bookings" label="Bookings" iconClass="fa-book" />
            <SidebarButton path="/offline-tickets" label="Offline Tickets" iconClass="fa-ticket-alt" />
            <SidebarButton path="/product" label="Add Product" iconClass="fa-plus-circle" />
          </div>
        </div>
        <div className="mb-4">
          <h3 className="text-gray-500 text-left px-4">Marketing</h3>
          <div>
            <SidebarButton path="/customers" label="Customers" iconClass="fa-users" />
            <SidebarButton path="/social" label="Social" iconClass="fa-share-alt" />
            <SidebarButton path="/reviews-ratings" label="Reviews & Ratings" iconClass="fa-star" />
          </div>
        </div>
        <div className="mb-4">
          <h3 className="text-gray-500 text-left px-4">General</h3>
          <div>
            <SidebarButton path="/business-details" label="Business Details" iconClass="fa-briefcase" />
            <SidebarButton path="/website-details" label="Website Details" iconClass="fa-globe" />
            <SidebarButton path="/dashboard" label="Dashboard" iconClass="fa-tachometer-alt" />
            <SidebarButton path="/integrations" label="Integrations" iconClass="fa-plug" />
          </div>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
