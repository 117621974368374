import * as yup from 'yup';

import { getFileSchema } from '../common';

export const websiteDetailSchemma = yup.object({
  primaryColor: yup
    .string()
    .matches(/^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6}|[0-9a-fA-F]{8})$/, 'Primary Color should be a valid hex code')
    .required('Primary Color is required.'),
  secondaryColor: yup
    .string()
    .matches(
      /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6}|[0-9a-fA-F]{8})$/,
      'Secondary Color should be a valid hex code'
    )
    .required('Secondary Color is required'),
  domain: yup
    .string()
    .matches(/^(([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,})|localhost:\d+$/, 'Domain is Invalid')
    .required('Domain is required'),
  logo: getFileSchema(['image/']).required("Logo is required'"),
  title: yup.string().required('Title is required'),
  description: yup.string().max(500).required('Description is required'),
  categories: yup
    .array(
      yup.object({
        title: yup.string().required('Category Title is required'),
        image: getFileSchema(['image/']).required('Category Image is required')
      })
    )
    .required('Atleast 1 Category is required')
    .min(1, 'Atleast 1 Category is required'),
  landingImages: yup
    .array(getFileSchema(['image/']).required())
    .max(5)
    .required('Landing Images are required'),
  faqs: yup
    .array(
      yup.object({
        question: yup.string().required('Question is required'),
        answer: yup.string().required('Answer is required')
      })
    )
    .required('Atleast 1 FAQ is required')
    .min(1, 'Atleast 1 FAQ is required'),
  termsAndCondition: yup.string().required('Terms and Conditions is required'),
  cancellationPolicy: yup.string().required('Cancellation Policies is required'),
  privacyPolicy: yup.string().required('Privacy Policies is required')
});

export type WebsiteDetailFormData = yup.InferType<typeof websiteDetailSchemma>;
