import * as yup from 'yup';

export const packageDetailSchema = yup.object().shape({
  packages: yup
    .array()
    .required()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        price: yup
          .number()
          .required()
          .typeError('Price must be a number')
          .positive('Price must be greater than zero')
          .required('Price is required'),
        totalTickets: yup.number().required(),
        availableTickets: yup.number().required().min(0, 'Available Tickets Cannot be negative'),
        discount: yup
          .number()
          .required()
          .min(0, 'Discount should be greater than 0')
          .max(100, 'Discount should be less than 100')
      })
    )
});

export type VenueCardFormData = yup.InferType<typeof packageDetailSchema>;
