import * as yup from 'yup';

import { OBJECT_ID_PATTERN } from '../../common';

export const offlineTicketSchema = yup
  .object({
    customerName: yup.string().required('Customer Name is required'),
    phoneNumber: yup
      .string()
      .required('Phone number is required')
      .matches(/^[0-9]{10}$/, 'Phone number must be a 10-digit number without any special characters'),
    listingId: yup.string().required('Product Selection is required').matches(OBJECT_ID_PATTERN),
    venueId: yup.string().required('Venue Selection is required').matches(OBJECT_ID_PATTERN),
    date: yup.string().required().min(1, 'Date is Required'),
    packages: yup.array(yup.number().min(0)).required(),
    bookingAmount: yup.number().required().min(1),
    discount: yup.number().required(),
    taxAmount: yup.number().required(),
    totalAmount: yup.number().required().min(1)
  })
  .required();

export type OfflineTicketFormData = yup.InferType<typeof offlineTicketSchema>;
