import React from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  header?: string;
  children: React.ReactNode;
  width?: string; // Accepts Tailwind width classes (e.g., "w-1/2", "w-full", "max-w-lg")
}

const Modal: React.FC<ModalProps> = ({ header, isOpen, onClose, children, width }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div
        className={`bg-white p-6 rounded-md shadow-lg flex flex-col max-h-[90vh] ${
          width || 'w-11/12 sm:w-2/3 md:max-w-4xl'
        }`}
      >
        {header && (
          <div className="flex justify-between items-center mb-6 flex-shrink-0">
            <h3 className="text-2xl font-bold text-center sm:text-left truncate">{header}</h3>
            {onClose && (
              <button onClick={onClose} className="text-xl font-bold text-gray-500 hover:text-gray-800">
                X
              </button>
            )}
          </div>
        )}

        {/* Scrollable content area */}
        <div className="ml-4 max-h-max overflow-y-auto pr-4">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
