import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../hooks/store';
import { authPropThunk } from '../redux/reducer/propSlice';

const AuthComponent = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const propId = useAppSelector(state => state.prop.propId);

  useEffect(() => {
    const authenticate = async () => {
      if (!propId) {
        try {
          await dispatch(authPropThunk());
        } catch (error) {
          // dispatch(logoutProp());
        }
      }
    };

    authenticate();
  }, []);

  return <>{children}</>;
};

export default AuthComponent;
