import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import Header from '../components/header/Header';
import Loading from '../components/loading/Loading';
import Sidebar from '../components/sidebar/Sidebar';
import { useAppSelector } from '../hooks/store';
import { authPropThunk } from '../redux/reducer/propSlice';

const ProtectedLayout: React.FC = () => {
  const isAuthenticated = !!useAppSelector(state => state.prop.propId);
  const isLoading = useAppSelector(state => state.prop.isLoading);
  const location = useLocation();

  if (isLoading[authPropThunk.pending.toString()]) {
    return <Loading />;
  }

  if (!isAuthenticated && location.pathname !== '/login') {
    // Redirect to login if user is not authenticated and not on the login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="p-2 flex-1 w-4/5 overflow-auto">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default ProtectedLayout;
