import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { listingSlice } from './reducer/listingSlice';
import { propSlice } from './reducer/propSlice';

const rootReducer = combineReducers({
  listing: listingSlice.reducer,
  prop: propSlice.reducer
});

const makeStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false
      })
  });
};

export const store = makeStore();
export const dispatch = store.dispatch;
export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
