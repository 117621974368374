import { GetThunkAPI } from '@reduxjs/toolkit';
import _ from 'lodash';

import axiosInstance from '../../utils/axios';
import { Listing } from '../../utils/entity/listing';
import { Venue } from '../../utils/entity/venue';
import { GetProductsResponse } from '../../utils/models/listing';
import { AddProductFormData } from '../../validation/operations/addOrEditProduct/addProductSchema';
import { AddVenueFormData } from '../../validation/operations/addOrEditProduct/addVenueSchema';
import { VenueCardFormData } from '../../validation/operations/products/venueCard';
import { AsyncThunkConfig, FileTypeToString, withApiErrorHandler } from '../common';

export const addListing = async (
  listingData: FileTypeToString<AddProductFormData>,
  { getState }: GetThunkAPI<AsyncThunkConfig>
) => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.post(`/prop/${propId}/listing`, listingData);
    return response.data;
  }, propId);
};

export const getListing = async (
  listingId: string,
  { getState }: GetThunkAPI<AsyncThunkConfig>
): Promise<Listing> => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.get(`/prop/${propId}/listing/${listingId}`);
    return response.data;
  }, propId);
};

export const updateListing = async (
  { listingId, listingData }: { listingId: string; listingData: FileTypeToString<AddProductFormData> },
  { getState }: GetThunkAPI<AsyncThunkConfig>
) => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.put(`/prop/${propId}/listing/${listingId}`, listingData);
    return response.data;
  }, propId);
};

export const getVenuesMeta = async (
  listingId: string,
  { getState }: GetThunkAPI<AsyncThunkConfig>
): Promise<Pick<Venue, 'id' | 'address' | 'phoneNumber' | 'slots'>[]> => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.get(`/prop/${propId}/listing/${listingId}/venues-meta`);
    return response.data;
  }, propId);
};

export const getProducts = async (
  _: void,
  { getState }: GetThunkAPI<AsyncThunkConfig>
): Promise<GetProductsResponse> => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.get(`/prop/${propId}/products`);
    return response.data;
  }, propId);
};

export const updateVenuePackages = async (
  {
    listingId,
    venueId,
    packages
  }: {
    listingId: string;
    venueId: string;
    packages: Pick<VenueCardFormData['packages'][number], 'id' | 'discount' | 'price' | 'totalTickets'>[];
  },
  { getState }: GetThunkAPI<AsyncThunkConfig>
) => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.put(
      `/prop/${propId}/listing/${listingId}/venue/${venueId}/packages`,
      { packages }
    );
    return response.data;
  }, propId);
};

export const addVenue = async (
  { listingId, veneuData }: { listingId: string; veneuData: AddVenueFormData },
  { getState }: GetThunkAPI<AsyncThunkConfig>
) => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const request: Venue = _.omit(
      {
        ...veneuData,
        propId: propId!,
        listingId,
        slots: [[[{ from: veneuData.startTime, to: veneuData.endTime }]]]
      },
      'startTime',
      'endTime',
      'date'
    );
    const response = await axiosInstance.post(`/prop/${propId}/listing/${listingId}/venues`, request);
    return response.data;
  }, propId);
};

export const getVenue = async (
  { listingId, venueId }: { listingId: string; venueId: string },
  { getState }: GetThunkAPI<AsyncThunkConfig>
) => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.get(`/prop/${propId}/listing/${listingId}/venue/${venueId}`);
    return response.data;
  }, propId);
};

export const updateVenue = async (
  { listingId, venueId, veneuData }: { listingId: string; venueId: string; veneuData: AddVenueFormData },
  { getState }: GetThunkAPI<AsyncThunkConfig>
) => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const request = _.omit(
      {
        ...veneuData,
        slots: [[[{ from: veneuData.startTime, to: veneuData.endTime }]]]
      },
      'startTime',
      'endTime',
      'date'
    );
    const response = await axiosInstance.put(
      `/prop/${propId}/listing/${listingId}/venue/${venueId}`,
      request
    );
    return response.data;
  }, propId);
};

export const savePackage = async (
  {
    listingId,
    venueId,
    packageDetail
  }: {
    listingId: string;
    venueId: string;
    packageDetail: AddVenueFormData['packages'][number];
  },
  { getState }: GetThunkAPI<AsyncThunkConfig>
) => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.put(
      `/prop/${propId}/listing/${listingId}/venue/${venueId}/package`,
      packageDetail
    );
    return response.data;
  }, propId);
};

export const deletePackage = async (
  {
    listingId,
    venueId,
    packageId
  }: {
    listingId: string;
    venueId: string;
    packageId: string;
  },
  { getState }: GetThunkAPI<AsyncThunkConfig>
) => {
  const propId = getState().prop.propId;
  return withApiErrorHandler(async () => {
    const response = await axiosInstance.delete(
      `/prop/${propId}/listing/${listingId}/venue/${venueId}/package/${packageId}`
    );
    return response.data;
  }, propId);
};
