import React from 'react';

const ReplyPage: React.FC = () => {
  return (
    <>
      <div className="ml-4 w-1/3">
        <div>
          <h1 className="text-2xl font-bold mt-2">Review reply</h1>
        </div>
        <div>
          <div className="bg-[#f5f5f5] rounded-md mt-4 h-auto pt-3 pl-2 pr-2 pb-2">
            <h3 className="font-bold text-lg flex justify-between">Workshop 1</h3>
            <p>⭐⭐⭐⭐⭐</p>
            <p className="font-medium mt-1">
              Wonderla recently started to new rides and I visited to experience those rides and also all the
              rides. New rides are adventures and very unique must try. Drop loop and rainbow loops
            </p>
            <p className="font-medium mt-1">
              <strong>Experienced On:</strong> 3 Oct 2024
            </p>
          </div>
        </div>
        <div>
          <p className="font-medium mt-2 flex justify-between">
            Reply <span className="text-sm font-normal mt-1">Maximum 1000 characters</span>
          </p>
          <textarea className="bg-[#f5f5f5] w-full mt-2 h-[180px] rounded-md"></textarea>
        </div>
        <div>
          <button className="font-medium bg-[#35e0a1] pl-10 rounded-md mt-2 pr-10 pt-1 pb-1">Add</button>
        </div>
      </div>
    </>
  );
};

export default ReplyPage;
