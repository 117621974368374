/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

const BookingsDetails = () => {
  const [bookingDate, setBookingDate] = useState('13th Oct 2024');
  const [status, setStatus] = useState('Type 1');

  const handleBookingDateChange = (e: { target: { value: string } }) => {
    setBookingDate(e.target.value);
  };

  const handleStatusChange = (e: { target: { value: string } }) => {
    setStatus(e.target.value);
  };

  return (
    <div className="ml-4 mt-2 font-medium">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Bookings Details</h1>
        {/* <button className="bg-green-500 text-white px-4 py-2 rounded">Add Product</button> */}
      </div>
      <div className="space-y-6 text-left">
        <div className="flex">
          <div className="w-4/5">
            <section className="mb-8">
              <h2 className="text-xl font-bold mb-4">#13081998</h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block font-bold">Customer Name</label>
                  <div className="relative">
                    <input
                      type="text"
                      value="Srikanda Venkata"
                      disabled
                      className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                    />
                    <a href="#" className="absolute right-2 top-2 text-black underline font-medium">
                      View bookings
                    </a>
                  </div>
                </div>
                <div>
                  <label className="block font-bold">
                    No. of Tickets
                    <input type="text" value="2" disabled className="bg-[#f6f6f6] mt-1 rounded p-2 w-full" />
                  </label>
                </div>
                <div>
                  <label className="block font-bold">
                    Phone Number
                    <input
                      type="text"
                      value="+91-8333766209"
                      disabled
                      className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                    />
                  </label>
                </div>
                <div>
                  <label className="block font-bold">Transaction ID</label>
                  <input
                    type="text"
                    value="8787589478237"
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-bold">Booking Source</label>
                  <input
                    type="text"
                    value="Website"
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-bold">Payment Mode</label>
                  <input
                    type="text"
                    value="Type 1"
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-bold">Transaction Date</label>
                  <input
                    type="text"
                    value="06th Oct 2024"
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-bold">Product</label>
                  <input
                    type="text"
                    value="Piyush Mishra - UdanKhatola Tour"
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-bold">Booking Date</label>
                  <select
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                    value={bookingDate}
                    onChange={handleBookingDateChange}
                  >
                    <option>13th Oct 2024</option>
                    <option>14th Oct 2024</option>
                    <option>15th Oct 2024</option>
                  </select>
                </div>
                <div>
                  <label className="block font-bold">Status</label>
                  <select
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option>Type 1</option>
                    <option>Type 2</option>
                    <option>Type 3</option>
                  </select>
                </div>
              </div>
            </section>
            <section>
              <h2 className="text-xl font-bold mb-4">Payment Details</h2>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label className="block font-bold">Booking Amount</label>
                  <input
                    type="text"
                    value="₹ 4999"
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-6/12"
                  />
                </div>
                <div>
                  <label className="block font-bold">Discount</label>
                  <input
                    type="text"
                    value="₹ 499"
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-6/12"
                  />
                </div>
                <div>
                  <label className="block font-bold">Tax Amount</label>
                  <input type="text" value="₹ 99" disabled className="bg-[#f6f6f6] mt-1 rounded p-2 w-6/12" />
                </div>
                <div>
                  <label className="block font-bold">Total Amount Paid</label>
                  <input
                    type="text"
                    value="₹ 4599"
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-6/12"
                  />
                </div>
                <div>
                  <label className="block font-bold">Total Amount to be collected</label>
                  <input type="text" value="₹ 0" disabled className="bg-[#f6f6f6] mt-1 rounded p-2 w-6/12" />
                </div>
              </div>
              <button className="bg-[#35e0a1] font-medium  px-10 py-2 rounded mt-4">Save</button>
            </section>
          </div>
          <div className="flex flex-col items-end space-y-4 ml-8">
            <button className="bg-[#35e0a1]  px-2 py-2 rounded w-40 font-medium">Date Change</button>
            <button className="bg-[#35e0a1]  px-2 py-2 font-medium rounded w-40">Cancel Booking</button>
            <button className="bg-[#35e0a1] font-medium px-2 py-2 rounded w-40">Amount Paid</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingsDetails;
