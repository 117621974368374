// components/Button.tsx
import React from 'react';

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  label: string;
  onClick?: () => void;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ label, onClick, className, ...rest }) => (
  <button onClick={onClick} className={className} {...rest}>
    {label}
  </button>
);

export default Button;
