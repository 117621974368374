import emailjs from 'emailjs-com';
import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

type FormData = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

const ContactForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async data => {
    try {
      // Send email using EmailJS
      await emailjs.send(
        'YOUR_SERVICE_ID', // Replace with your EmailJS Service ID
        'YOUR_TEMPLATE_ID', // Replace with your EmailJS Template ID
        data,
        'YOUR_PUBLIC_KEY' // Replace with your EmailJS Public Key
      );
      alert('Message sent successfully!');
      reset(); // Clear the form after successful submission
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    }
  };

  return (
    <section
      id="contact-us"
      className="flex flex-col md:flex-row justify-center mt-10 p-4 items-center bg-purple-500"
    >
      {/* Left Section */}
      <div className="text-center md:text-left md:w-1/2 px-8 pt-10">
        <h1 className="text-white text-3xl font-bold mb-4">Get in touch with us</h1>
        <p className="text-white text-lg">Open your digital store & grow your business today</p>
      </div>

      {/* Right Section */}
      <div className="bg-white shadow-lg rounded-lg p-8 md:w-1/3 w-full my-12">
        <h2 className="text-gray-800 text-xl font-semibold mb-4">Contact us</h2>
        <p className="text-gray-600 text-sm mb-6">
          Complete the information below and we will get back to you soon
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          {/* Name Field */}
          <div>
            <input
              type="text"
              {...register('name', { required: 'Name is required' })}
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
              placeholder="Name"
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
          </div>

          {/* Email Field */}
          <div>
            <input
              type="email"
              {...register('email', {
                required: 'Email is required',
                pattern: { value: /^\S+@\S+$/i, message: 'Invalid email' }
              })}
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
              placeholder="Email"
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
          </div>

          {/* Phone Field */}
          <div>
            <input
              type="tel"
              {...register('phone')}
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
              placeholder="Phone number"
            />
          </div>

          {/* Message Field */}
          <div>
            <textarea
              {...register('message', { required: 'Message is required' })}
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
              placeholder="Write your message"
              rows={4}
            ></textarea>
            {errors.message && <p className="text-red-500 text-sm">{errors.message.message}</p>}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-3 bg-black text-white rounded-md hover:bg-gray-800 transition"
          >
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
