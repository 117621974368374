import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { BusinessDetail, WebsiteDetail } from '../../utils/entity/prop';
import { addLoadingReducers, BaseState, ThunkReducerMap } from '../common';
import {
  authProp,
  createProp,
  getBusinessDetail,
  getPresignedPost,
  getWebsiteDetail,
  loginProp,
  logoutProp,
  propExist,
  saveBusinessDetail,
  saveWebsiteDetail
} from '../thunk/propThunk';

const propThunks = {
  propExistThunk: createAsyncThunk('prop/exist', propExist),
  loginPropThunk: createAsyncThunk('prop/loginProp', loginProp),
  createPropThunk: createAsyncThunk('prop/createProp', createProp),
  authPropThunk: createAsyncThunk('prop/authProp', authProp),
  logoutPropThunk: createAsyncThunk('prop/logoutProp', logoutProp),
  getPresignedPostThunk: createAsyncThunk('prop/getPresignedPost', getPresignedPost),
  saveBusinessDetailThunk: createAsyncThunk('prop/saveBusinessDetail', saveBusinessDetail),
  getBusinessDetailThunk: createAsyncThunk('prop/getBusinessDetail', getBusinessDetail),
  saveWebsiteDetailThunk: createAsyncThunk('prop/saveWebsiteDetail', saveWebsiteDetail),
  getWebsiteDetailThunk: createAsyncThunk('prop/getWebsiteDetail', getWebsiteDetail)
};
export const {
  propExistThunk,
  loginPropThunk,
  createPropThunk,
  authPropThunk,
  logoutPropThunk,
  getPresignedPostThunk,
  saveBusinessDetailThunk,
  getBusinessDetailThunk,
  saveWebsiteDetailThunk,
  getWebsiteDetailThunk
} = propThunks;

interface PropState extends BaseState {
  propId?: string;
  businessDetail?: BusinessDetail;
  websiteDetail?: WebsiteDetail;
}

const initialState: PropState = {
  isLoading: { [authPropThunk.pending.toString()]: true }, //Defailting this value so that the loading symbol apears when a protectedLayout is opened
  error: {},
  propId: undefined
};
export const propSlice = createSlice({
  name: 'prop',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addLoadingReducers(
      builder,
      propThunks,
      new ThunkReducerMap<PropState, typeof propThunks>()
        .addCase(loginPropThunk.fulfilled, (state, action) => {
          state.propId = action.payload?.id;
        })
        .addCase(createPropThunk.fulfilled, (state, action) => {
          state.propId = action.payload?.id;
        })
        .addCase(authPropThunk.fulfilled, (state, action) => {
          state.propId = action.payload?.id;
        })
        .addCase(logoutPropThunk.fulfilled, state => {
          _.assign(state, _.cloneDeep(initialState));
          delete state.isLoading[authPropThunk.pending.toString()];
        })
        .addCase(getBusinessDetailThunk.fulfilled, (state, action) => {
          state.businessDetail = action.payload;
        })
        .addCase(getWebsiteDetailThunk.fulfilled, (state, action) => {
          state.websiteDetail = action.payload;
        })
    );
  }
});
