import { useState } from 'react';

type Customer = {
  name: string;
  phone: string;
  bookingCount: number;
};

const customers: Customer[] = [
  { name: 'Venkata Srivatsav Konda', phone: '+918333876209', bookingCount: 4 },
  { name: 'Venkata Srivatsav Konda', phone: '+918333876210', bookingCount: 1 },
  { name: 'Venkata Srivatsav Konda', phone: '+918333876211', bookingCount: 5 },
  { name: 'Venkata Srivatsav Konda', phone: '+918333876212', bookingCount: 7 },
  { name: 'Venkata Srivatsav Konda', phone: '+918333876213', bookingCount: 10 },
  { name: 'Venkata Srivatsav Konda', phone: '+918333876214', bookingCount: 6 },
  { name: 'Venkata Srivatsav Konda', phone: '+918333876215', bookingCount: 9 }
];

const CustomersPage = () => {
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filters, setFilters] = useState({
    product: '',
    source: '',
    city: '',
    status: '',
    bookingDate: '',
    transactionDate: ''
  });

  // Handle filter change
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  // Toggle single customer selection
  const handleCustomerSelect = (phone: string) => {
    if (selectedCustomers.includes(phone)) {
      setSelectedCustomers(selectedCustomers.filter(customer => customer !== phone));
    } else {
      setSelectedCustomers([phone]);
      setSelectAll(false); // Deselect all if a single customer is selected
    }
  };

  // Toggle all customer selection
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCustomers([]);
    } else {
      setSelectedCustomers(customers.map(customer => customer.phone));
    }
    setSelectAll(!selectAll);
  };

  return (
    <div className="flex-grow">
      <div className="flex justify-between pl-2 pr-2">
        <div className="w-4/5">
          <h1 className="text-2xl font-semibold mb-6">Customers</h1>
          <p className="text-left w-full flex justify-end font-medium underline mb-2">Download</p>
          {/* Customer Table */}
          <table className="w-full p-4 bg-white shadow-md rounded-md">
            <thead>
              <tr className="bg-[#d9d9d9] flex h-[50px] items-center rounded-t-md">
                <th className="p-2 text-left w-[100px]">
                  <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                </th>
                <th className="p-2 text-center w-[100px]">Customer</th>
                <th className="p-2 text-center w-[200px]">Phone No.</th>
                <th className="p-2 text-center w-[150px]">Booking Count</th>
              </tr>
            </thead>
            <tbody>
              {customers.map(customer => (
                <tr key={customer.phone} className="border-t flex font-medium">
                  <td className="p-2 w-[100px] items-center flex">
                    <input
                      type="checkbox"
                      checked={selectedCustomers.includes(customer.phone)}
                      onChange={() => handleCustomerSelect(customer.phone)}
                    />
                  </td>
                  <td className="p-2 w-[100px] text-center underline">{customer.name}</td>
                  <td className="p-2 w-[200px] justify-center flex items-center">{customer.phone}</td>
                  <td className="p-2 w-[150px] justify-center flex items-center">{customer.bookingCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="w-52">
          <h3 className="font-semibold mt-4">Filter</h3>
          <div className="mt-4 space-y-4 font-medium">
            <div>
              <label htmlFor="product">By product</label>
              <select
                id="product"
                name="product"
                value={filters.product}
                onChange={handleFilterChange}
                className="w-full mt-1 p-2 bg-[#f6f6f6]"
              >
                <option value="">Select Product</option>
              </select>
            </div>
            <div>
              <label htmlFor="source">By Source</label>
              <select
                id="source"
                name="source"
                value={filters.source}
                onChange={handleFilterChange}
                className="w-full mt-1 p-2 bg-[#f6f6f6]"
              >
                <option value="">Select Source</option>
              </select>
            </div>
            <div>
              <label htmlFor="city">By City</label>
              <select
                id="city"
                name="city"
                value={filters.city}
                onChange={handleFilterChange}
                className="w-full mt-1 p-2 bg-[#f6f6f6]"
              >
                <option value="">Select City</option>
              </select>
            </div>
            <div>
              <label htmlFor="status">By Status</label>
              <select
                id="status"
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
                className="w-full mt-1 p-2 bg-[#f6f6f6]"
              >
                <option value="">Select Status</option>
              </select>
            </div>
            <div>
              <label htmlFor="bookingDate">By Booking Date</label>
              <input
                type="date"
                id="bookingDate"
                name="bookingDate"
                value={filters.bookingDate}
                onChange={handleFilterChange}
                className="w-full mt-1 p-2 bg-[#f6f6f6]"
              />
            </div>
            <div>
              <label htmlFor="transactionDate">By Transaction Date</label>
              <input
                type="date"
                id="transactionDate"
                name="transactionDate"
                value={filters.transactionDate}
                onChange={handleFilterChange}
                className="w-full mt-1 p-2 bg-[#f6f6f6]"
              />
            </div>
            <button className="w-1/2 rounded-md bg-[#35e0a1] text-black p-2 mt-4">Filter</button>
            <p className="text-red-500 mt-1">No filters selected</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersPage;
