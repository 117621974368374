import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ReviewProps {
  review: {
    title: string;
    rating: number;
    description: string;
    experiencedOn: string;
    status: string;
  };
}

const reviewsData = [
  {
    id: 1,
    title: 'Workshop 1',
    rating: 5,
    description:
      'Wonderla recently started new rides and I visited to experience those rides and also all the rides. New rides are adventures and very unique must try: Drop loop and rainbow loops.',
    experiencedOn: '3 Oct 2024',
    status: 'Replied'
  },
  {
    id: 2,
    title: 'Workshop 1',
    rating: 5,
    description:
      'Wonderla recently started new rides and I visited to experience those rides and also all the rides. New rides are adventures and very unique must try: Drop loop and rainbow loops.',
    experiencedOn: '3 Oct 2024'
  },
  {
    id: 3,
    title: 'Workshop 1',
    rating: 5,
    description:
      'Wonderla recently started new rides and I visited to experience those rides and also all the rides. New rides are adventures and very unique must try: Drop loop and rainbow loops.',
    experiencedOn: '3 Oct 2024',
    status: 'Replied'
  },
  {
    id: 4,
    title: 'Workshop 1',
    rating: 5,
    description:
      'Wonderla recently started new rides and I visited to experience those rides and also all the rides. New rides are adventures and very unique must try: Drop loop and rainbow loops.',
    experiencedOn: '3 Oct 2024',
    status: 'Replied'
  }
  // Add more reviews as needed
];

const ReviewsRatings: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    title: '',
    description: ''
  });
  // Filter Handling
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleAddVenue = () => {
    // alert("Add Venue clicked");
    navigate(`/reviews-ratings/reply-page`);
  };

  return (
    <div className="flex w-full">
      <div className="w-4/5">
        <h1 className="text-2xl font-bold mt-2 ml-4">Reviews Ratings</h1>

        <div className="w-full flex gap-4 pl-4 pr-4">
          <div className="grid grid-cols-2 gap-4 w-full">
            {reviewsData.map(review => (
              <div key={review.id} className="">
                <div className="bg-[#f5f5f5] rounded-md mt-4 h-auto pt-3 pl-2 pr-2 pb-2">
                  <h3 className="font-bold text-lg flex justify-between">
                    {review.title}
                    <p className="font-medium text-sm underline">View Booking Details </p>
                  </h3>
                  <p>⭐⭐⭐⭐⭐</p>
                  <p className="font-medium mt-1">{review.description}</p>
                  <p className="font-medium mt-1">
                    <strong>Experienced On:</strong> {review.experiencedOn}
                  </p>
                  {review.status === 'Replied' ? (
                    <p className="font-medium mt-1">
                      Wonderla recently started new rides and I visited to experience those rides and also all
                      the rides. New rides are adventures and very unique must try: Drop loop and rainbow
                      loops.
                    </p>
                  ) : (
                    <button
                      onClick={handleAddVenue}
                      className=" mt-2 font-medium flex justify-end w-full mb-1 mr-1 underline"
                    >
                      Reply
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-52 ">
        <h3 className="font-semibold mt-4">Filter</h3>
        <div className="mt-4 space-y-4 font-medium">
          <div>
            <label htmlFor="product">By product</label>
            <select id="product" name="product" className="w-full mt-1 p-2 bg-[#f6f6f6]">
              <option value="">Select Product</option>
              {/* Add more products as needed */}
            </select>
          </div>
          <div>
            <label htmlFor="source">By Source</label>
            <select id="source" name="source" className="w-full mt-1 p-2 bg-[#f6f6f6]">
              <option value="">Select Source</option>
            </select>
          </div>
          <div>
            <label htmlFor="city">By City</label>
            <select id="city" name="city" className="w-full mt-1 p-2 bg-[#f6f6f6]">
              <option value="">Select City</option>
            </select>
          </div>
          <div>
            <label htmlFor="status">By Status</label>
            <select id="status" name="status" className="w-full mt-1 p-2 bg-[#f6f6f6]">
              <option value="">Select Status</option>
            </select>
          </div>
          <div>
            <label htmlFor="bookingDate">By Booking Date</label>
            <input type="date" id="bookingDate" name="bookingDate" className="w-full mt-1 p-2 bg-[#f6f6f6]" />
          </div>
          <div>
            <label htmlFor="transactionDate">By Transaction Date</label>
            <input
              type="date"
              id="transactionDate"
              name="transactionDate"
              className="w-full mt-1 p-2 bg-[#f6f6f6]"
            />
          </div>
          <button className="w-1/2 rounded-md bg-[#35e0a1] text-black p-2 mt-4">Filter</button>
          <p className="text-red-500 mt-1">No filters selected</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewsRatings;
