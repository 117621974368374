import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { logoutPropThunk } from './propSlice';
import { Listing } from '../../utils/entity/listing';
import { Venue } from '../../utils/entity/venue';
import { Product } from '../../utils/models/listing';
import { addLoadingReducers, BaseState, ThunkReducerMap } from '../common';
import {
  addListing,
  addVenue,
  deletePackage,
  getListing,
  getProducts,
  getVenue,
  getVenuesMeta,
  savePackage,
  updateListing,
  updateVenue,
  updateVenuePackages
} from '../thunk/listingThunk';

interface ListingState extends BaseState {
  listings: Array<Listing>;
  listingMap: Record<string, Listing>;
  venueMap: Record<string, Venue>;
  products: Array<Product>;
}

const initialState: ListingState = {
  listings: [],
  listingMap: {},
  venueMap: {},
  products: [],
  isLoading: {},
  error: {}
};

const listingThunks = {
  addListingThunk: createAsyncThunk('listing/addListing', addListing),
  getListingThunk: createAsyncThunk('listing/getListing', getListing),
  updateListingThunk: createAsyncThunk('listing/updateListing', updateListing),
  getVenuesMetaThunk: createAsyncThunk('listing/getVenuesMeta', getVenuesMeta),
  addVenueThunk: createAsyncThunk('listing/addVenue', addVenue),
  getVenueThunk: createAsyncThunk('listing/getVenue', getVenue),
  updateVenueThunk: createAsyncThunk('listing/updateVenue', updateVenue),
  savePackageThunk: createAsyncThunk('listing/savePackage', savePackage),
  deletePackageThunk: createAsyncThunk('listing/deletePackage', deletePackage),
  getProductsThunk: createAsyncThunk('listing/getProducts', getProducts),
  updateVenuePackagesThunk: createAsyncThunk('listing/updateVenuePackages', updateVenuePackages)
};
export const {
  addListingThunk,
  addVenueThunk,
  getListingThunk,
  updateListingThunk,
  getVenuesMetaThunk,
  getVenueThunk,
  getProductsThunk,
  updateVenueThunk,
  savePackageThunk,
  deletePackageThunk,
  updateVenuePackagesThunk
} = listingThunks;

export const listingSlice = createSlice({
  name: 'listing',
  initialState,
  reducers: {},
  extraReducers: builder => {
    addLoadingReducers(
      builder,
      listingThunks,
      new ThunkReducerMap<ListingState, typeof listingThunks>()
        .addCase(logoutPropThunk.fulfilled, state => {
          _.assign(state, _.cloneDeep(initialState));
        })
        .addCase(getProductsThunk.fulfilled, (state, action) => {
          state.products = action.payload.products;
        })
        .addCase(getListingThunk.fulfilled, (state, action) => {
          state.listingMap = { ...state.listingMap, [action.payload.id!]: action.payload };
        })
        .addCase(getVenueThunk.fulfilled, (state, action) => {
          state.venueMap = { ...state.venueMap, [action.payload.id!]: action.payload };
        })
    );
  }
});

// export const {} = listingSlice.actions;
