import React from 'react';

const Integrations: React.FC = () => {
  return (
    <div>
      <h1 className="text-2xl w-full font-semibold mb-4">Payment Integration</h1>
      <div className="w-5/12 mb-1">
        <p className="block text-sm font-medium text-gray-700">Api key Code</p>
        <input
          className="mt-1 font-medium block w-full p-2 border border-[#f6f6f6] bg-[#f6f6f6] rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
          type="text"
          placeholder="Enter Primary color hex code"
        />
      </div>
      <div className="w-5/12 mt-2 mb-1">
        <p className="block text-sm font-medium text-gray-700">Api Secret key</p>
        <input
          className="mt-1 font-medium block w-full p-2 border border-[#f6f6f6] bg-[#f6f6f6] rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
          type="text"
          placeholder="Enter Primary color hex code"
        />
      </div>
      <div className="w-5/12 mt-2 mb-1">
        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
          Select City*
        </label>
        <select
          id="city"
          name="city"
          className="mt-1 block w-full p-2 border border-[#f6f6f6] bg-[#f6f6f6] rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
          required
        >
          <option value="">Select City from list</option>
          <option value="City1">City1</option>
          <option value="City2">City2</option>
          <option value="City3">City3</option>
        </select>
      </div>
    </div>
  );
};

export default Integrations;
