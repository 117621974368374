import React from 'react';

import aboutUs from '../../../assets/landing/about-us.jpeg';

const AboutUs: React.FC = () => {
  return (
    <section id="about" className="pt-20 bg-[#F9FAFB]">
      <div className="container mx-auto px-6 lg:px-16 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16">
        {/* Text Content */}
        <div className="md:my-14">
          <h2 className="text-center md:text-left text-lg font-semibold mb-2 text-[#FF543E]">ABOUT US</h2>
          <p className="bri font-bold text-3xl sm:text-4xl mb-4">
            Where creativity, business & unique vision meets
          </p>
          <div className="int text-base sm:text-lg space-y-6">
            <p>
              We are a group of young tech enthusiasts who had a conversation with a passionate event
              organizer. He expressed frustration about lacking the resources to create his own online demand
              generation channel or tools to manage leads effectively on platforms like Instagram or WhatsApp.
            </p>
            <p>
              This inspired us to create Zittle—a platform designed to empower entrepreneurs and small
              business owners in the service, leisure, and entertainment industries. Our mission is to help
              them scale their businesses by harnessing the potential of online demand and managing their
              operations seamlessly.
            </p>
          </div>
        </div>

        {/* Image Content */}
        <div className="flex justify-center">
          <img
            src={aboutUs}
            alt="About Us preview"
            className="w-full h-auto object-cover rounded-2xl"
            onLoad={() => URL.revokeObjectURL(aboutUs)}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
