import * as yup from 'yup';

export const addVenueSchema = yup.object({
  date: yup.date().min(new Date()).required('Date is required'),
  startTime: yup.date().required('Start Time is required'),
  endTime: yup
    .date()
    .required('End Time is required')
    .test('is-after-startTime', 'End Time must be after Start Time', function (value) {
      const { startTime } = this.parent; // Access other fields in the same object
      return value > startTime; // Check if endTime is after startTime
    }),
  address: yup.string().max(200).required('Venue Address is required'),
  location: yup.string().required('Venue Location is required'),
  city: yup.string().required('City is required'),
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .matches(/^[0-9]{10}$/, 'Phone number must be a 10-digit number without any special characters'),
  packages: yup
    .array(
      yup.object({
        id: yup.string().transform(value => (value === '' ? undefined : value)),
        title: yup.string().required('Package title is required'),
        description: yup.string(),
        totalTickets: yup.number().required('Number of tickets is required'),
        price: yup.number().required('Price is required'),
        discount: yup
          .number()
          .required('Discount % is required')
          .min(0, 'Discount should be greater than 0')
          .max(100, 'Discount should be less than 100')
      })
    )
    .min(1)
    .required('Packages is Required')
});

export type AddVenueFormData = yup.InferType<typeof addVenueSchema>;
