import _ from 'lodash';
import React from 'react';
import {
  FieldErrors,
  FieldPathByValue,
  FieldValues,
  PathValue,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form';

import { Option } from './InputSelect';

interface InputMultiSelectProps<TFieldValues extends FieldValues> {
  name: FieldPathByValue<TFieldValues, Array<string>>;
  setValue: UseFormSetValue<TFieldValues>;
  watch: UseFormWatch<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  label: string;
  options: Option[];
  placeholder?: string;
  maxTags?: number;
  required?: boolean;
}

export const InputMultiSelect = <TFieldValues extends FieldValues>({
  name,
  label,
  options,
  placeholder = 'Select options',
  maxTags = 4,
  setValue,
  watch,
  errors,
  required
}: InputMultiSelectProps<TFieldValues>) => {
  const selectedIds: string[] = watch(name) ?? [];

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;

    if (selectedId && !selectedIds.some(value => value === selectedId)) {
      const isValid = options.some(option => option.id === selectedId);
      if (isValid && selectedIds.length < maxTags) {
        const updatedOptions = [...selectedIds, selectedId];
        setValue(name, updatedOptions as PathValue<TFieldValues, typeof name>);
      }
    }
  };

  const handleRemoveTag = (id: string) => {
    const updatedOptions = selectedIds.filter(value => value !== id);
    setValue(name, updatedOptions as PathValue<TFieldValues, typeof name>);
  };

  const selectedOptions = selectedIds
    .map((id, index) => {
      const option = options.find(option => option.id === id);
      if (!option) {
        delete selectedIds[index];
      }
      return option;
    })
    .filter(option => !_.isUndefined(option)) as Option[];
  const error = _.get(errors, name)?.message as string;
  return (
    <div className="w-full mb-2">
      <label htmlFor={name} className="flex mb-1 font-medium items-end justify-between">
        <p>
          {label} {required && <span className="text-red-600">*</span>}
        </p>
        {maxTags && <p className="font-normal text-[14px]"> (Maximum {maxTags} Tags)</p>}
      </label>
      <select
        className="block w-full p-2 border border-[#f6f6f6] bg-[#f6f6f6] rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500"
        onChange={handleSelectChange}
        value=""
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {option.value}
          </option>
        ))}
      </select>

      <div className="flex flex-wrap mt-2 gap-2">
        {selectedOptions.map(option => (
          <div
            key={option.id}
            className="flex items-center px-2 py-1 bg-gray-200 rounded-full shadow-sm text-sm"
          >
            {option.value}
            <button
              type="button"
              className="ml-2 text-red-500 hover:text-red-700"
              onClick={() => handleRemoveTag(option.id)}
            >
              ✕
            </button>
          </div>
        ))}
      </div>
      {error && <p className="text-red-600 text-sm font-semibold mt-1">{error}</p>}
    </div>
  );
};
