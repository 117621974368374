export const Button: React.FC = () => {
  return (
    <a
      href="#contact-us"
      className="int bg-[#0ACE82] text-[#202322] font-semibold px-6 py-3 rounded-xl hover:bg-green-600"
    >
      Contact Us
    </a>
  );
};
