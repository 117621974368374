import _ from 'lodash';
import { FieldPathByValue, FieldValues, PathValue, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { InputFormFieldProps } from './Input';

export type Option = {
  id: string;
  value: string;
};

interface CommonInputSelectProps<TFieldValues extends FieldValues> extends InputFormFieldProps<TFieldValues> {
  options: Option[];
  label: string;
  required: boolean;
  placeholder: string;
  multiple?: boolean;
  disabled?: boolean;
}

interface InputSelectSingleProps<TFieldValues extends FieldValues>
  extends CommonInputSelectProps<TFieldValues>,
    InputFormFieldProps<TFieldValues> {
  multiple?: false;
}

interface InputSelectMultipleProps<TFieldValues extends FieldValues>
  extends CommonInputSelectProps<TFieldValues> {
  name: FieldPathByValue<TFieldValues, []>;
  multiple: true;
  setValue: UseFormSetValue<TFieldValues>;
  watch: UseFormWatch<TFieldValues>;
  maxTags: number;
}

type InputSelectProps<TFieldValues extends FieldValues> =
  // | InputSelectMultipleProps<TFieldValues>
  InputSelectSingleProps<TFieldValues>;

const InputSelect = <TFieldValues extends FieldValues>({
  name,
  options,
  label,
  placeholder,
  required,
  register,
  disabled,
  errors
}: InputSelectProps<TFieldValues>): JSX.Element => {
  const error = _.get(errors, name)?.message as string;
  return (
    <div className="w-full mb-2">
      <label htmlFor={name} className="block mb-1 font-medium">
        {label} {required && <span className="text-red-600">*</span>}
      </label>
      <select
        id={name}
        className="block w-full p-2 border border-[#f6f6f6] bg-[#f6f6f6] rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500"
        disabled={disabled}
        {...register?.(name, { required, disabled, value: '' as PathValue<TFieldValues, typeof name> })}
      >
        <option value="" disabled hidden>
          {placeholder ?? label}
        </option>
        {options.map((option, index: number) => (
          <option key={index} value={option.id}>
            {option.value}
          </option>
        ))}
      </select>
      {error && <p className="text-red-600 text-sm font-semibold mt-1">{error}</p>}
    </div>
  );
};

export default InputSelect;
