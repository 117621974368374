import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Categories, { CategoriesProps } from './Categories';
import Input from '../../components/input/Input';
import InputFile, { uploadLocalFiles } from '../../components/input/InputFile';
import InputTextArea from '../../components/input/InputTextArea';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getWebsiteDetailThunk, saveWebsiteDetailThunk } from '../../redux/reducer/propSlice';
import { PresignedPostUploadType } from '../../utils/models/listing';
import { withErrorHandler } from '../../utils/utils';
import { WebsiteDetailFormData, websiteDetailSchemma } from '../../validation/general/websiteDetails';

// function removeId<T>(obj: T, visited = new WeakSet<object>()): void {
//   // If the object is null or undefined, stop recursion
//   if (obj === null) {
//     return;
//   }

//   // Ensure obj is an object before adding it to the WeakSet
//   if (typeof obj !== 'object') {
//     return;
//   }

//   // If we've already visited this object, stop to prevent infinite loops
//   if (visited.has(obj)) {
//     return;
//   }

//   // Mark this object as visited
//   visited.add(obj);

//   // If the object is an array, iterate over each element
//   if (Array.isArray(obj)) {
//     obj.forEach(item => removeId(item, visited));
//   }
//   // If the object is a non-null object (not an array), check each property
//   else if (typeof obj === 'object') {
//     Object.keys(obj).forEach(key => {
//       // Check if the key is '_id'
//       if (key === '_id') {
//         // Check if the property is configurable before attempting to delete
//         const descriptor = Object.getOwnPropertyDescriptor(obj, key);
//         if (descriptor && descriptor.configurable) {
//           // Delete the '_id' property from the object
//           delete (obj as Record<string, unknown>)[key];
//         }
//       } else {
//         // Recurse into nested properties
//         removeId((obj as Record<string, unknown>)[key], visited);
//       }
//     });
//   }
// }

const WebsiteDetails: React.FC = () => {
  const websiteDetail = useAppSelector(state => state.prop.websiteDetail);
  const values: WebsiteDetailFormData = { ...websiteDetail } as unknown as WebsiteDetailFormData;
  // removeId(values);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger
  } = useForm<WebsiteDetailFormData>({
    resolver: yupResolver(websiteDetailSchemma),
    defaultValues: { faqs: [{}] },
    values
  });
  const dispatch = useAppDispatch();
  const faqs = watch('faqs');

  useEffect(() => {
    dispatch(getWebsiteDetailThunk());
  }, [dispatch]);

  const onSubmit = async (data: WebsiteDetailFormData) => {
    withErrorHandler(async () => {
      const logo = (await uploadLocalFiles(PresignedPostUploadType.PROP_LOGO, data.logo))[0];
      const landingImages = await uploadLocalFiles(
        PresignedPostUploadType.PROP_LANDING_IMAGES,
        ...data.landingImages
      );
      setValue(
        'landingImages',
        landingImages.map(loc => ({ loc }))
      );
      const categoriesImageLoc = await uploadLocalFiles(
        PresignedPostUploadType.PROP_CATEGORY_IMAGES,
        ...data.categories.map(category => category.image)
      );
      setValue(
        'categories',
        data.categories.map((obj, index) => ({ ...obj, image: { loc: categoriesImageLoc[index] } }))
      );
      await dispatch(
        saveWebsiteDetailThunk({
          ...data,
          logo,
          landingImages,
          faqs: faqs.map(faq => ({ question: faq.question, answer: faq.answer })),
          categories: data.categories.map((obj, index) => ({ ...obj, image: categoriesImageLoc[index] }))
        })
      ).unwrap();
    }, 'Successfully saved Website Details');
  };

  const commonProps = {
    topLabel: true,
    required: true,
    register,
    errors
  };
  const faqsError = _.get(errors, 'faqs')?.message as string;
  return (
    <div className="flex flex-col lg:flex-row w-full min-h-screen">
      <main className="p-8 w-full">
        <form onSubmit={handleSubmit(onSubmit)} className="flex items-start">
          <div className="w-[85%]">
            <section className="mb-8 w-full">
              <h3 className="text-2xl w-full font-semibold mb-4">Website Theme</h3>
              <div className="flex w-full gap-4">
                <div className="w-5/12">
                  <Input
                    label="Primary Color"
                    name="primaryColor"
                    type="text"
                    placeholder="Enter Primary color hex code"
                    {...commonProps}
                  />
                  <Input
                    label="Secondary Color"
                    name="secondaryColor"
                    type="text"
                    placeholder="Enter Secondary color hex code"
                    {...commonProps}
                  />
                  <Input
                    label="Website Domain"
                    name="domain"
                    type="text"
                    placeholder="Enter your website domain"
                    {...commonProps}
                  />
                </div>
                <div className="w-5/12">
                  <InputFile
                    name="logo"
                    label="Upload Logo"
                    required
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                    addtionalText={
                      <p className="text-sm font-medium mt-1 text-gray-300">Click here to add logo</p>
                    }
                    accept="image/*"
                  />
                </div>
              </div>
            </section>

            {/* Website Details Section */}
            <section className="mb-8 w-full">
              <h3 className="text-2xl font-semibold mb-4">Website Details</h3>
              <div className="flex w-full gap-4">
                <div className="w-5/12 gap-4">
                  <Input label="Title" name="title" type="text" placeholder="Enter Title" {...commonProps} />
                  <InputTextArea
                    label="Description"
                    name="description"
                    maxLength={500}
                    placeholder="Enter Description"
                    {...commonProps}
                  />
                  <div className="col-span-2">
                    <Categories
                      {...({ register, errors, setValue, watch, trigger } as unknown as CategoriesProps)}
                    />
                  </div>
                </div>
                <div className="w-5/12">
                  <InputFile
                    name="landingImages"
                    label="Landing Images"
                    required
                    multiple
                    maxFiles={5}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                    addtionalText={
                      <p className="text-sm font-medium mt-1 text-gray-300">Click here to add images</p>
                    }
                    accept="image/*"
                  />
                </div>
              </div>
            </section>

            {/* FAQs Section */}
            <section className="mb-8 w-full">
              <div className="w-full">
                <div className="flex w-full">
                  <h3 className="text-2xl font-semibold w-[73%] mb-4">FAQ&apos;s</h3>
                  <button
                    onClick={async () => {
                      const isValid = await trigger('faqs');
                      if (isValid) {
                        setValue('faqs', [...faqs, { question: '', answer: '' }]);
                      }
                    }}
                    type="button"
                    className="border-2 border-black bg-gray-200 rounded-md px-5"
                  >
                    Add FAQ
                  </button>
                </div>
                {faqsError && <p className="text-red-600 text-sm font-semibold">{faqsError}</p>}
              </div>
              <div className="flex flex-wrap w-full gap-4">
                {faqs.map((faq, index) => (
                  <div key={index} className="flex flex-col w-5/12 space-y-2">
                    <Input
                      label={`Question ${index + 1}`}
                      name={`faqs.${index}.question`}
                      type="text"
                      placeholder={`Enter Question ${index + 1}`}
                      {...commonProps}
                    />
                    <InputTextArea
                      label={`Answer ${index + 1}`}
                      name={`faqs.${index}.answer`}
                      maxLength={500}
                      placeholder={`Enter Answer ${index + 1}`}
                      {...commonProps}
                    />
                  </div>
                ))}
              </div>
            </section>

            {/* Policies Section */}
            <section className="mb-8">
              <h3 className="text-2xl font-semibold mb-4">Policies</h3>
              <div className="flex flex-wrap gap-4">
                <div className="w-5/12">
                  <InputTextArea
                    label="Terms & Conditions"
                    name="termsAndCondition"
                    maxLength={500}
                    placeholder="Enter Terms & Conditions"
                    {...commonProps}
                  />
                </div>
                <div className="w-5/12">
                  <InputTextArea
                    label="Cancellation Policies"
                    name="cancellationPolicy"
                    maxLength={500}
                    placeholder="Enter Cancellation Policies"
                    {...commonProps}
                  />
                </div>
                <div className="w-5/12">
                  <InputTextArea
                    label="Privacy Policies"
                    name="privacyPolicy"
                    maxLength={500}
                    placeholder="Enter Privacy Policies"
                    {...commonProps}
                  />
                </div>
              </div>
            </section>
          </div>
          <button
            type="submit"
            className="mt-12 sticky top-14 p-2 bg-[#35e0a1] text-black rounded-md font-semibold hover:bg-teal-600 transition duration-300"
          >
            Save Changes
          </button>
        </form>
      </main>
    </div>
  );
};

export default WebsiteDetails;
