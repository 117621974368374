import toast, { Renderable, Toast, ValueOrFunction } from 'react-hot-toast';

type Message = ValueOrFunction<Renderable, Toast>;
export const successMessage = (text: Message) => {
  toast.success(text);
};

export const errorMessage = (text: Message) => {
  toast.error(text);
};
