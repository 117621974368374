import React from 'react';

import LoadingSpin from './LoadingSpin';

const Loading: React.FC = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <LoadingSpin />
    </div>
  );
};

export default Loading;
